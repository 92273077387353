import React from 'react'

export default function PostLoader() {
  return (
    <div className = 'flex flex-col min-h-screen animate-pulse'>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-1/2 lg:w-1/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-2/3 lg:w-2/5 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-2/3 lg:w-2/3 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-2/3 lg:w-2/5 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-2/3 lg:w-2/5 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-1/3 lg:w-1/5 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-2/3 lg:w-3/5 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-2/3 lg:w-3/5 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-1/3 lg:w-1/5 bg-black-100 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 p-4 pt-0 mt-2 flex flex-col'>
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
        <div className = 'h-3 w-full lg:w-4/5 bg-black-100 mt-2' />
      </div>
    </div>
  )
}