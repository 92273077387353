import React from 'react'
import { marked } from 'marked'

export default function ProjectCard(props) {
  const createMarkup = (content) => {
    const html = marked(content)
    return { __html: html }
  }
  return (
    <div className = 'w-full lg:w-2/5 my-1 px-4 flex-flex-col'>
      {
        props.project?.url ? (
          <a className = 'hover:underline underline-offset-4 font-code text-lg' href = {props.project.url}>{props.project.title}</a>
        ) : (
          <span className = 'font-code text-lg'>{props.project.title}</span>
        )
      }
      <div className = 'text-black-50 text-sm font-sans mt-4 whitespace-pre-wrap' dangerouslySetInnerHTML = {createMarkup(props.project.body)}/>
    </div>
  )
}