import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import { initializeAdTracking } from '@/lib/analytics'

import Home from '@/pages/Home'
import Verification from '@/pages/Verification'
import Unsubscribe from '@/pages/Unsubscribe'
import Thoughts from '@/pages/Thoughts'
import Post from '@/pages/Post'
import Projects from '@/pages/Projects'
import Friends from '@/pages/Friends'
import OuterSpace from '@/pages/OuterSpace'
import E404 from '@/pages/E404'

import '@/_base.scss'

export default function App() {
  useEffect(() => {
    initializeAdTracking()
  })
  return (
    <BrowserRouter>
      <div className = 'flex flex-col min-h-screen bg-gradient-to-r from-black-100 to-black-50'>
        <Switch>
          <Route exact path = '/' component = {Home} />
          <Route exact path = '/verification' component = {Verification} />
          <Route exact path = '/unsubscribe' component = {Unsubscribe} />
          <Route exact path = '/thoughts' component = {Thoughts} />
          <Route exact path = '/thoughts/:slug' component = {Post} />
          <Route exact path = '/projects' component = {Projects} />
          <Route exact path = '/friends' component = {Friends} />
          <Route exact path = '/outer-space' component = {OuterSpace} />
          <Route exact path = '/404' component = {E404} />
          <Route><Redirect to = '/404'/></Route>
        </Switch>
      </div>
    </BrowserRouter>
  )
}