import React from 'react'

export default function PostCardLoader() {
  return (
    <div className = 'flex flex-col animate-pulse'>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
      <div className = 'w-full lg:w-2/5 my-2 px-4 flex items-center'>
        <span className = 'text-black-50 font-code text-lg'>•</span>
        <div className = 'h-3 w-4/5 bg-black-100' />
      </div>
    </div>
  )
}