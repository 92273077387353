import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import { marked } from 'marked'

import { trackPageView } from '@/lib/analytics'

import PostLoader from '@/components/PostLoader'

export default function Post() {
  const { slug } = useParams()
  const [post, setPost] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const createMarkup = (content) => {
    const html = marked(content)
    return { __html: html }
  }
  useEffect(() => {
    async function fetchPost() {
      const res = await fetch(`/api/posts/${slug}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Headers': 'Origin',
        }
      })
      if(res.status === 200) {
        const { post } = await res.json()
        setPost(post)
        setLoading(false)
      } else {
        const { error } = await res.json()
        setError(error ? error : 'Something went wrong, please try again.')
        setLoading(false)
      }
    }
    trackPageView(window.location.pathname + window.location.search)
    fetchPost()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className = 'flex flex-col min-h-screen bg-white-50 text-black-50 p-4'>
      <Helmet>
        <title>{post ? `${post?.title} | Donald Koo` : `A Thought | Donald Koo`  }</title>
        <meta
          name = 'description'
          content = {post ? post?.body : `A thought by Donald Koo`}
        />
        <meta property="og:image" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:secure_url" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Donald Koo og image" />
      </Helmet>
      <div className = 'flex justify-between mb-4'>
        <NavLink to = '/thoughts' className = 'text-xs tracking-superwide uppercase text-black-100 my-2 font-code whitespace-nowrap'>&lt; Thoughts</NavLink>
        <span className = 'text-xs tracking-superwide uppercase text-black-100 my-2 ml-1 underline font-code underline-offset-2 text-right'>{post ? post.title : null}</span>
      </div>
      {
        loading ? <PostLoader /> : post ? (
          <div className = 'w-full lg:w-2/5 my-2 px-4'>
            <div className = 'text-black-50 font-sans whitespace-pre-wrap mb-4'>{moment(post.createdAt).format('MMMM YYYY')}</div>
            <div className = 'text-black-50 font-sans whitespace-pre-wrap mb-8' dangerouslySetInnerHTML = {createMarkup(post.body)}/>
          </div>
        ) : error ? (
          <div className = 'w-full lg:w-2/5 my-1 px-4 flex-flex-col'>
            <span className = 'font-code text-lg'>ERROR</span>
            <span className = 'text-black-50 text-sm font-sans mt-4 whitespace-pre-wrap'>{error}</span>
          </div>
        ) : <PostLoader />
      }
    </div>
  )
}