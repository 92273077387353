import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import PleaseWait from '@/assets/videos/pls.mp4'

import { trackPageView } from '@/lib/analytics'

export default function E404() {
  useEffect(() => {
    trackPageView(window.location.pathname + window.location.search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className = 'flex flex-col text-white-50 bg-gradient-to-r from-black-100 to-black-50 duration-300'>
      <Helmet>
        <title>404 | Donald Koo</title>
        <meta
          name = 'description'
          content = 'Donald Koo, entrepreneur.'
        />
        <meta property="og:image" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:secure_url" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Donald Koo og image" />
      </Helmet>
      <div className = 'flex justify-between m-4 z-10 self-end'>
        <NavLink to = '/' className = 'cursor-pointer hover:text-white-50 text-xs text-gray-200 font-code tracking-superwide mt-2 uppercase'>Where did you go?</NavLink>
      </div>
      <div className = 'absolute h-screen w-screen overflow-hidden'>
        <video className = 'relative h-full w-full object-cover object-top self-top' autoPlay loop muted>
          <source src = {PleaseWait} type = 'video/mp4' />
        </video>
      </div>
    </div>
  )
}