import React, { useState, useEffect } from 'react'
import { useLocation, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { trackPageView } from '@/lib/analytics'

export default function Unsubscribe() {
  const location = useLocation()
  const [error, setError] = useState(false)
  const [unsubscribed, setUnsubscribed] = useState(false)
  useEffect(() => {
    async function unsubscribe() {
      // check valid code
      // if bad code, send to 404, else proceed
      let searchParams = await new URLSearchParams(location.search)
      let code = await searchParams.get('code')

      if(!code) {
        // display error
        setError(`Something went wrong, please try again.`)
      } else {
        const res = await fetch(`/api/subscribers/unsubscribe/${code}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': 'Origin'
          }
        })
        if(res.status === 200) {
          setUnsubscribed(true)
        } else if (res.status === 404 || res.status === 500) {
          setError(`Something went wrong, please try again.`)
        } else {
          const { error } = await res.json()
          setError(error)
        }
      }
    }
    trackPageView(window.location.pathname + window.location.search)
    unsubscribe()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className = 'flex flex-col min-h-full h-screen text-white-50 bg-gradient-to-r from-black-100 to-black-50 duration-300'>
      <Helmet>
        <title>Unsubscribe | Donald Koo</title>
        <meta
          name = 'description'
          content = 'Donald Koo, entrepreneur.'
        />
        <meta property="og:image" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:secure_url" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Donald Koo og image" />
      </Helmet>
      <div className = 'flex justify-between m-4 z-10'>
        <NavLink to = '/' className = 'cursor-pointer hover:text-white-50 text-xs text-gray-300 font-code tracking-superwide mt-2 uppercase transition-all ease-in-out duration-300 delay-75 whitespace-nowrap'>&lt; Home</NavLink>
        <span className = 'cursor-pointer hover:text-white-50 text-xs text-gray-300 font-code tracking-superwide mt-2 ml-1 uppercase underline underline-offset-2 transition-all ease-in-out duration-300 delay-75 text-right'>Unsubscribe</span>
      </div>
      {
        unsubscribed ? (
          <div className = 'flex flex-col h-full justify-center self-center items-center text-center w-4/5 lg:w-2/5 slow-fade'>
            <span className = 'text-white-50 text-lg font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>UNSUBSCRIBED</span>
            <span className = 'text-sm text-white-50 font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>You've been unsubscribed successfully.</span>
          </div>
        ) : error ? (
          <div className = 'flex flex-col h-full justify-center self-center items-center text-center w-4/5 lg:w-2/5 slow-fade'>
            <span className = 'text-white-50 text-lg font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>ERROR</span>
            <span className = 'text-sm text-white-50 font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>{error}</span>
          </div>
        ) : (
          <div className = 'flex flex-col h-full justify-center self-center items-center text-center w-4/5 lg:w-2/5 slow-fade'>
            <span className = 'text-white-50 text-lg font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>UNSUBSCRIBING...</span>
            <span className = 'text-sm text-white-50 font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>Sit tight, we're unsubscribing your email.</span>
          </div>
        )
      }
      <div className = 'flex flex-col text-center m-1'>
        <div className = 'my-4'>
          <NavLink to = '/outer-space' className = 'cursor-pointer hover:text-white-50 text-tiny text-gray-300 font-code tracking-superwide mt-2 uppercase transition-all ease-in-out duration-300 delay-75'>Love yourself.</NavLink>
        </div>
      </div>
    </div>
  )
}