import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { trackPageView } from '@/lib/analytics'

import './styles.scss'

export default function Home() {
  const [view, setView] = useState('home')
  const [email, setEmail] = useState()
  const [requesting, setRequesting] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)
  useEffect(() => {
    trackPageView(window.location.pathname + window.location.search)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const changeView = (newView) => {
    if(newView === 'subscribe') {
      if(view === 'subscribe') {
        setView('home')
      } else {
        setView('subscribe')
      }
    } else {
      if(view === 'about') {
        setView('home')
      } else {
        setView('about')
      }
    }
  }
  const subscribe = async () => {
    setRequesting(true)
    const params = {
      email,
    }
    const res = await fetch(`/api/subscribers/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin'
      },
      body: JSON.stringify(params)
    })
    if(res.status === 201) {
      setSuccess(true)
      setRequesting(false)
    } else {
      const { error } = await res.json()
      setError(error ? error : 'Something went wrong, please try again.')
      setRequesting(false)
    }
  }
  return (
    <div className = 'flex flex-col min-h-full h-screen text-white-50 bg-gradient-to-r from-black-100 to-black-50 duration-300'>
      <Helmet>
        <title>Donald Koo</title>
        <meta
          name = 'description'
          content = 'Donald Koo, entrepreneur.'
        />
        <meta property="og:image" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:secure_url" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Donald Koo og image" />
      </Helmet>
      <div className = 'flex justify-between m-4 z-10'>
        <span className = 'cursor-pointer hover:text-white-50 text-xs text-gray-300 font-code tracking-superwide mt-2 uppercase transition-all ease-in-out duration-300 delay-75 whitespace-nowrap' onClick = {() => changeView('subscribe')}>{view === 'subscribe' ? '< Home' : 'Subscribe'}</span>
        <span className = 'cursor-pointer hover:text-white-50 text-xs text-gray-300 font-code tracking-superwide mt-2 ml-1 uppercase transition-all ease-in-out duration-300 delay-75 text-right' onClick = {() => changeView('about')}>{view === 'about' ? 'Home' : 'About'}</span>
      </div>
      {
        view === 'about' ? (
          <div className = 'flex flex-col h-full justify-center self-center items-center text-center w-4/5 lg:w-2/5 slow-fade'>
            <span className = 'text-white-50 text-lg font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>ABOUT</span>
            <span className = 'text-sm text-white-50 font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>My space in the ether.</span>
            <span className = 'text-sm text-white-50 font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>I write my thoughts, meditations, and whatever my soul wants to share.</span>
          </div>
        ) : view === 'subscribe' ? (
          <div className = 'flex flex-col h-full justify-center self-center items-center text-center w-4/5 lg:w-2/5 slow-fade'>
            <span className = 'text-white-50 text-lg font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>Subscribe</span>
            <span className = 'text-sm text-white-50 font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>Periodic updates, basically when I feel like it.</span>
            <input
              className = 'box-border w-56 bg-white-0 text-black-100 p-1 text-sm outline-none font-code mt-2'
              type = 'email'
              placeholder = 'email'
              onChange = {(e) => setEmail(e.target.value)}
            />
            {
              error ? (
                <span className = 'font-sans uppercase p-0 text-sm mt-1 -mb-1 text-red-500'>{error}</span>
              ) : null
            }
            {
              requesting ? (
                <span className = 'text-sm text-white-50 font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>One moment...</span>
              ) : success ? (
                <span className = 'text-sm text-white-50 font-code tracking-superwide my-2 uppercase transition-all ease-in-out duration-300 delay-75'>Congrats. You're subscribed. Click the link we just emailed you to verify your email.</span>
              ) : <button onClick = {() => subscribe()} className = 'box-border w-56 text-sm my-2 bg-white-0 hover:bg-white-50 outline-none text-black-100 font-code uppercase'>Subscribe</button>
            }
          </div>
        ) : (
          <div className = 'flex flex-col h-full justify-center self-center items-center'>
            <NavLink to = '/thoughts' className = 'cursor-pointer hover:text-white-50 text-lg text-gray-300 font-code tracking-superwide mt-2 uppercase transition-all ease-in-out duration-300 delay-75'>
              <span className = 't-s'>T</span>
              <span className = 'h-s'>h</span>
              <span className = 'o-s'>o</span>
              <span className = 't-s'>u</span>
              <span className = 'o-s'>g</span>
              <span className = 'oh-s'>h</span>
              <span className = 'h-s'>t</span>
              <span className = 'oh-s'>s</span>
            </NavLink>
            <NavLink to = '/projects' className = 'cursor-pointer hover:text-white-50 text-lg text-gray-300 font-code tracking-superwide mt-2 uppercase transition-all ease-in-out duration-300 delay-75'>
              <span className = 'oh-s'>P</span>
              <span className = 't-s'>r</span>
              <span className = 'h-s'>o</span>
              <span className = 'oh-s'>j</span>
              <span className = 't-s'>e</span>
              <span className = 'o-s'>c</span>
              <span className = 'o-s'>t</span>
              <span className = 'h-s'>s</span>
            </NavLink>
            <NavLink to = '/friends' className = 'cursor-pointer hover:text-white-50 text-lg text-gray-300 font-code tracking-superwide mt-2 uppercase transition-all ease-in-out duration-300 delay-75'>
              <span className = 'h-s'>F</span>
              <span className = 'oh-s'>r</span>
              <span className = 't-s'>i</span>
              <span className = 't-s'>e</span>
              <span className = 'h-s'>n</span>
              <span className = 'oh-s'>d</span>
              <span className = 'o-s'>s</span>
            </NavLink>
            <div className = 'cursor-default text-lg text-gray-700 line-through font-code tracking-superwide mt-2 uppercase transition-all ease-in-out duration-300 delay-75'>
              <span className = 't-s'>A</span>
              <span className = 'h-s'>d</span>
              <span className = 'o-s'>v</span>
              <span className = 'oh-s'>i</span>
              <span className = 't-s'>s</span>
              <span className = 'oh-s'>o</span>
              <span className = 't-s'>r</span>
              <span className = 'h-s'>y</span>
              <span className = 't-s'>(</span>
              <span className = 'oh-s'>f</span>
              <span className = 't-s'>u</span>
              <span className = 't-s'>l</span>
              <span className = 'h-s'>l</span>
              <span className = 'h-s'>)</span>
            </div>
          </div>
        )
      }
      <div className = 'flex flex-col text-center m-1'>
        <div className = 'my-4'>
          <NavLink to = '/outer-space' className = 'cursor-pointer hover:text-white-50 text-tiny text-gray-300 font-code tracking-superwide mt-2 uppercase transition-all ease-in-out duration-300 delay-75'>Love yourself.</NavLink>
        </div>
      </div>
    </div>
  )
}