import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ProjectCard from '@/components/ProjectCard'
import ProjectCardLoader from '@/components/ProjectCardLoader'

import { trackPageView } from '@/lib/analytics'

export default function Friends() {
  const [loading, setLoading] = useState(true)
  const [selectedPage, setSelectedPage] = useState(0)
  const [maxPage, setMaxPage] = useState(0)
  const [projects, setProjects] = useState([])
  const [error, setError] = useState()
  async function fetchProjects(page) {
    setSelectedPage(page ? page : 0)
    const res = await fetch(`/api/projects/friends?page=${page ? page : 0}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Origin',
      },
    })
    if(res.status === 200) {
      const { projects, maxPage } = await res.json()
      setProjects(projects)
      setMaxPage(maxPage)
      setLoading(false)
    } else {
      const { error } = await res.json()
      setError(error ? error : 'Something went wrong, please try again.')
      setLoading(false)
    }
  }
  useEffect(() => {
    trackPageView(window.location.pathname + window.location.search)
    fetchProjects()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className = 'flex flex-col min-h-screen bg-white-50 text-black-50 p-4 pb-8'>
      <Helmet>
        <title>Friends | Donald Koo</title>
        <meta
          name = 'description'
          content = 'Donald Koo, entrepreneur.'
        />
        <meta property="og:image" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:secure_url" content="%PUBLIC_URL%/assets/ogimage.png" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:alt" content="Donald Koo og image" />
      </Helmet>
      <div className = 'flex justify-between mb-4'>
        <NavLink to = '/' className = 'text-xs tracking-superwide uppercase text-black-100 my-2 font-code whitespace-nowrap'>&lt; Home</NavLink>
        <span className = 'text-xs tracking-superwide uppercase text-black-100 my-2 ml-1 underline font-code underline-offset-2 text-right'>Projects</span>
      </div>
      {
        loading ? <ProjectCardLoader />
        : projects?.length > 0 ? (
          <div className = 'flex flex-col'>
            {
              projects.map(project => {
                return <ProjectCard project = {project} />
              })
            }
            {
              maxPage >= 1 ? (
                <div className = 'flex flex-nowrap items-center m-0'>
                  <div className = 'relative z-10 m-0 py-2 px-4'>
                    {
                      selectedPage === 0 ?
                      <button disabled className = 'cursor-default relative text-gray-300 font-code mr-2'>Back</button>
                      : selectedPage === maxPage ? <button onClick = {() => fetchProjects(selectedPage - 1)} className = 'relative font-code hover:text-black-50 text-gray-700 mr-2'>Back</button>
                      : <button onClick = {() => fetchProjects(selectedPage - 1)} className = 'relative font-code hover:text-black-50 text-gray-700 mr-2'>Back</button>
                    }
                    {
                      selectedPage === maxPage ?
                      <button disabled className = 'cursor-default relative text-gray-300 font-code ml-2'>Next</button>
                      : <button onClick = {() => fetchProjects(selectedPage + 1)} className = 'relative font-code hover:text-black-50 text-gray-700 ml-2'>Next</button>
                    }
                  </div>
                </div>
              ) : null
            }
          </div>
        ) : error ? (
          <div className = 'w-full lg:w-2/5 my-1 px-4 flex-flex-col'>
            <span className = 'font-code text-lg'>ERROR</span>
            <span className = 'text-black-50 text-sm font-sans mt-4 whitespace-pre-wrap'>{error}</span>
          </div>
        ) : <ProjectCardLoader />
      }
    </div>
  )
}