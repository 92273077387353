import React from 'react'

export default function ProjectCardLoader() {
  return (
    <div className = 'flex flex-col animate-pulse'>
      <div className = 'w-full lg:w-2/5 m-4 mt-2 flex flex-col'>
        <div className = 'h-4 w-1/5 bg-black-100 ml-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 m-4 flex flex-col'>
        <div className = 'h-4 w-1/5 bg-black-100 ml-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 m-4 flex flex-col'>
        <div className = 'h-4 w-1/5 bg-black-100 ml-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 m-4 flex flex-col'>
        <div className = 'h-4 w-1/5 bg-black-100 ml-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 m-4 flex flex-col'>
        <div className = 'h-4 w-1/5 bg-black-100 ml-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 m-4 flex flex-col'>
        <div className = 'h-4 w-1/5 bg-black-100 ml-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 m-4 flex flex-col'>
        <div className = 'h-4 w-1/5 bg-black-100 ml-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
      </div>
      <div className = 'w-full lg:w-2/5 m-4 flex flex-col'>
        <div className = 'h-4 w-1/5 bg-black-100 ml-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
        <div className = 'h-4 w-4/5 bg-black-100 ml-2 mt-2' />
      </div>
    </div>
  )
}